import DataS from "./data.s";

export default class DocumentS extends DataS {
  getByEmployee(STUDENT_ID) {
    return this.get('/documents/' + STUDENT_ID)
  }

  remove(data) {
    return this.post('/documents/delete', data)
  }

  createDiploma({DESIGNATION, SPECIALITY, LEVEL, LANGUAGE, OBTENEDDATE, OBTENEDSTAB, STUDENT_ID, PIECE_TYPE, FILE}) {
    return this.post('/documents/upload-student', {
      DESIGNATION,
      SPECIALITY,
      PIECE_TYPE,
      LEVEL,
      LANGUAGE,
      OBTENEDDATE,
      OBTENEDSTAB,
      EMPLOYEE: STUDENT_ID,
      FILE
    })
  }
}
