export default class Candidate {
  constructor(
    {
      EMAIL,
      PHONE_NUMBER,
      LASTNAME,
      FIRSTNAME,
      COUNTRY,
      BIRTHDATE,
      BIRTHPLACE,
      GENDER,
      DEPARTMENT,
      CIVIL_STATUS,
      FORMATION_TYPE,
      ADMISSION_DIPLOMA,
      LAST_YEAR_RANK,
      SCHOOL_OBTENTION,
      FIRST_YEAR_UNIVERSITY,
      EMERGENCY_CONTACT,
      FATHER_CONTACT,
      MOTHER_CONTACT,
      FORMATION_FIRST_CHOICE,
      FORMATION_SECOND_CHOICE,
      CYCLE,
      MENTION_OBTAINED,
      IS_PROFESSIONAL,
      CODE,
      LAST_YEAR_SITUATION,
      INTERTAINMENT,
      IS_HANDICAPED,
      FIRST_SPORT,
      EMERGENCY_NAME,
      FATHER_NAME,
      FATHER_JOB,
      MOTHER_NAME,
      MOTHER_JOB,
      FIRST_YEAR_UNIVERSITY_TYPE,
      SECOND_LANGUAGE,
      FIRST_LANGUAGE,
      REGION_ORIGIN,
      LIVING_TOWN,
    }) {
    this.EMAIL = EMAIL
    this.PHONE_NUMBER = PHONE_NUMBER
    this.LASTNAME = LASTNAME
    this.FIRSTNAME = FIRSTNAME
    this.COUNTRY = typeof COUNTRY === 'object' ? COUNTRY.name : COUNTRY
    this.BIRTHDATE = BIRTHDATE
    this.BIRTHPLACE = BIRTHPLACE
    this.GENDER = GENDER
    this.DEPARTMENT = DEPARTMENT
    this.CIVIL_STATUS = typeof CIVIL_STATUS === 'object' ? CIVIL_STATUS.name : CIVIL_STATUS
    this.FORMATION_TYPE = FORMATION_TYPE
    this.ADMISSION_DIPLOMA = ADMISSION_DIPLOMA
    this.LAST_YEAR_RANK = LAST_YEAR_RANK
    this.SCHOOL_OBTENTION = SCHOOL_OBTENTION
    this.FIRST_YEAR_UNIVERSITY = FIRST_YEAR_UNIVERSITY
    this.EMERGENCY_CONTACT = EMERGENCY_CONTACT
    this.FATHER_CONTACT = FATHER_CONTACT
    this.MOTHER_CONTACT = MOTHER_CONTACT
    this.FORMATION_FIRST_CHOICE = typeof FORMATION_FIRST_CHOICE === 'object' ? FORMATION_FIRST_CHOICE.Speciality_Name : FORMATION_FIRST_CHOICE
    this.FORMATION_SECOND_CHOICE = typeof FORMATION_SECOND_CHOICE === 'object' ? FORMATION_SECOND_CHOICE.Speciality_Name : FORMATION_SECOND_CHOICE
    this.CYCLE = typeof CYCLE === 'object' ? CYCLE.Cycle_Name : CYCLE
    this.MENTION_OBTAINED = MENTION_OBTAINED
    this.IS_PROFESSIONAL = Boolean(IS_PROFESSIONAL)
    this.CODE = CODE
    this.LAST_YEAR_SITUATION = LAST_YEAR_SITUATION
    this.INTERTAINMENT = INTERTAINMENT
    this.IS_HANDICAPED = Boolean(IS_HANDICAPED)
    this.FIRST_SPORT = FIRST_SPORT
    this.EMERGENCY_NAME = EMERGENCY_NAME
    this.FATHER_NAME = FATHER_NAME
    this.FATHER_JOB = FATHER_JOB
    this.MOTHER_NAME = MOTHER_NAME
    this.MOTHER_JOB = MOTHER_JOB
    this.FIRST_YEAR_UNIVERSITY_TYPE = FIRST_YEAR_UNIVERSITY_TYPE
    this.SECOND_LANGUAGE = typeof SECOND_LANGUAGE === 'object' ? SECOND_LANGUAGE.name : SECOND_LANGUAGE
    this.FIRST_LANGUAGE = typeof FIRST_LANGUAGE === 'object' ? FIRST_LANGUAGE.name : FIRST_LANGUAGE
    this.REGION_ORIGIN = typeof REGION_ORIGIN === 'object' ? REGION_ORIGIN.name : REGION_ORIGIN
    this.LIVING_TOWN = typeof LIVING_TOWN === 'object' ? LIVING_TOWN.name : LIVING_TOWN
  }

  get hasNullProps() {
    let data = {...this}
    let props = Object.getOwnPropertyNames(data)
    for (const prop of props) {
      let val = data[prop]
      if (typeof val === 'undefined' || val === '') {
        console.log(prop)
        return true
      }
    }
  }

}