const CookieC = {
  YEAR: '2023-2024',
  FP_DATA: 'DATA_STD',
  FP_APPLICATION: 'APP_STD',
  FP_SESSION: 'SESS_STD',
  FP_CANDIDATE: 'FP_CANDIDATE',
  FP_STATUS: 'STAT_STD',
  FP_IDCLIENT: '837648936059-gk1nn2thj1mg0djknsijf88c542d6pnn.apps.googleusercontent.com',
  FP_VERSION: 'VERSION_STD',
  VERSION: '1.0.0',
  TTL: 1800,
  // SERVER: 'http://127.0.0.1:3002',
  // CV_SERVER_URL: 'http://localhost:3001',
   CV_SERVER_URL: 'https://galiocv.myiuc.com',
    SERVER: 'https://galioserver.myiuc.com/',

  // 172.16.145.102  
  MEDIA: 'https://igoserver.myiuc.com/media/picture',
  COLOR_PLANNING: {
    'ValidatedAndBilled': "rgb(71,173,103)",
    'ValidatedButNotBilled': "rgb(178,234,197)",
    'PendingValidation': "rgb(255,201,107)",
    'NotValidated': "rgba(235, 85, 59, 1)",
    'Validated2': "rgba(235, 85, 59, 1)",
  }
}

export default CookieC
